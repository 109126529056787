import request from "@/utils/request";

//获取消息列表
export function msgGetList(params) {
  return request({
    url: `/message/mgr/getList`,
    method: "get",
    params: params,
  });
}
//设置消息为已读
export function readMessage(params) {
  return request({
    url: `/message/mgr/readMessage/${params}`,
    method: "PUT",
  });
}
//设置消息为已读
export function countUnReadMsg(params) {
  return request({
    url: `/message/mgr/countUnReadMsg`,
    method: "GET",
    params,
  });
}
//设置消息为已读
export function readAll(params) {
  return request({
    url: `/message/mgr/readAll`,
    method: "PUT",
  });
}
