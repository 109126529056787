<!--  -->
<template>
  <div class="approval-detail">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '固定资产详情',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->

    <div class="base-info card">
      <div class="row1">
        <div class="title">
          <p class="r"></p>
          <p class="name">{{ info.faName }}</p>
        </div>
      </div>
      <div class="row2">
        <span class="label">资产编号：</span>
        <span class="value">{{ info.faCode }}</span>
      </div>
      <div class="row2">
        <span class="label">资产型号：</span>
        <span class="value">{{ info.modelType }}</span>
      </div>
      <div class="row2">
        <span class="label">创建日期：</span>
        <span class="value">{{ info.createTime }}</span>
      </div>
      <div class="row2">
        <span class="label">资产金额：</span>
        <span class="value">￥{{ (info.faPrice / 100).toFixed(0) }}</span>
      </div>
      <div class="row2">
        <span class="label">资产状态：</span>
        <span class="value">{{ faStatus }}</span>
      </div>
      <div class="row2">
        <span class="label">使用日期：</span>
        <span class="value">{{ info.useTime }}</span>
      </div>
      <div class="row2">
        <span class="label">资产标签：</span>
        <span class="value">{{ tagStyle }}</span>
      </div>
      <div class="row2">
        <span class="label">部门：</span>
        <span class="value">{{ info.departmentName }}</span>
      </div>
      <div class="row2">
        <span class="label">负责人：</span>
        <span class="value">{{ info.principalName }}</span>
      </div>
      <div class="row2">
        <span class="label">位置：</span>
        <span class="value">{{ info.faLocation }}</span>
      </div>
      <div class="row2">
        <span class="label">备注：</span>
        <span class="value">{{ info.remarkDesc }}</span>
      </div>
      <div class="row2" v-if="info.faStatus == 2">
        <span class="label">故障描述：</span>
        <span class="value">{{ info.troubleReason || "" }}</span>
      </div>
      <div class="row2" v-if="info.faStatus == 3">
        <span class="label">报废描述：</span>
        <span class="value">{{ info.wasteReason }}</span>
      </div>
      <div class="row3">
        <span class="label">资产图片：</span>
        <div class="value">
          <img
            v-for="(file, i) in info.faPicList"
            :key="i"
            :src="file.url"
            alt=""
            class="img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryFixedAssetsInfo,
  wasteAssets,
  assetsTroubled,
  updateFaStatus,
} from "@/api/fixedAssets/index.js";

import approvalAvatar from "@/assets/images/approvalDetail/approvalAvatar.png";
import edit from "@/assets/images/approvalDetail/edit.png";
import cancel from "@/assets/images/approvalDetail/cancel.png";
import { Dialog } from "vant";
import { Toast } from "vant";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    return {
      processConfigInfo: {},
      info: {},
      nodesList: [],
      approvalAvatar,
      edit,
      cancel,
      formVisible: false,
      formVisiblebf: false,
      form: {
        approveStatus: -1,
      },
      wasteReason: "", //报废原因
      troubleReason: "", //故障原因
    };
  },
  name: "approvalDetail",
  components: { TopWrapper },
  computed: {
    type() {
      return this.$route.query.type;
    },

    faStatus() {
      const obj = {
        1: "正常",
        2: "故障",
        3: "报废",
      };
      return obj[this.info.faStatus] || "--";
    },
    tagStyle() {
      const obj = {
        1: "未贴",
        2: "已贴",
        3: "无法粘贴",
      };
      return obj[this.info.tagStyle] || "--";
    },
  },
  filters: {},
  created() {},
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      queryFixedAssetsInfo({
        bizCode: this.$route.query.bizCode,
      }).then((res) => {
        this.info = res.data;
      });
    },
    // 撤销
    // handleCancel() {
    //   Dialog.confirm({
    //     title: "确定撤销",
    //     message: "是否确认撤销当前审批？",
    //   }).then(
    //     (res) => {
    //       cancleApply({
    //         applyCode: this.$route.query.applyCode,
    //       }).then((res) => {
    //         Toast("撤消成功");
    //         this.$router.back();
    //       });
    //     },
    //     (rej) => {}
    //   );
    // },

    onsubmit() {
      let postData = {
        bizCode: this.$route.query.bizCode,
        troubleReason: this.troubleReason,
      };
      assetsTroubled(postData).then((res) => {
        let { msg, code } = res;
        Toast(msg);
        this.$router.back();
      });
    },
    onsubmitbf() {
      let postData = {
        bizCode: this.$route.query.bizCode,
        wasteReason: this.wasteReason,
      };
      wasteAssets(postData).then((res) => {
        let { msg, code } = res;
        Toast(msg);
        this.$router.back();
      });
    },

    // 编辑-带code
    handleEdit() {
      this.$router.push({
        path: "/applyCar",
        query: {
          applyCode: this.$route.query.applyCode,
          editType: 1,
        },
      });
    },
    //故障
    handleGZ() {
      this.troubleReason = "";
      this.formVisible = true;
    },
    //取消故障
    handleCancelGZ() {
      let postData = {
        bizCode: this.$route.query.bizCode,
        type: 2,
      };
      updateFaStatus(postData).then((res) => {
        let { msg, code } = res;
        Toast(msg);
        this.$router.back();
      });
    },
    //报废
    handleBF() {
      this.wasteReason = "";
      this.formVisiblebf = true;
    },
    //取消报废
    handleCancelBF() {
      let postData = {
        bizCode: this.$route.query.bizCode,
        type: 3,
      };
      updateFaStatus(postData).then((res) => {
        let { msg, code } = res;
        Toast(msg);
        this.$router.back();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.approval-detail {
  min-height: 100%;
  background: #fafafa;
  width: 7.5rem;
  position: relative;
  padding: 1px;
  .card {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    background: #fff;
    margin-bottom: 0.3rem;
    border: 1px solid transparent;
  }
  .base-info {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    .row1 {
      margin-bottom: 0.16rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        line-height: 0.48rem;
        display: flex;
        align-items: center;
        .r {
          width: 0.06rem;
          height: 0.28rem;
          background: #0094ee;
          border-radius: 0.03rem;
          margin-right: 0.1rem;
        }
        .name {
          font-size: 0.34rem;
          font-weight: bold;
          color: #333333;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .row2 {
      // height: 0.37rem;
      font-size: 0.26rem;
      color: #999999;
      line-height: 0.37rem;
      display: flex;
      // justify-content: space-between;
      margin-bottom: 0.3rem;
      .label {
        font-size: 0.26rem;
        font-weight: 400;
        color: #999;
        line-height: 0.37rem;
      }
      .value {
        width: 5.3rem;
        font-size: 0.26rem;
        font-weight: 400;
        color: #333333;
        line-height: 0.37rem;
      }
    }
    .row3 {
      display: flex;
      flex-direction: column;
      .label {
        font-size: 0.26rem;
        font-weight: 400;
        color: #999;
        line-height: 0.37rem;
      }
      .value {
        display: flex;
        .img {
          margin-top: 0.2rem;
          width: 2.3rem;
          height: 1.7rem;
          border-radius: 0.16rem;
        }
      }
    }
  }

  .values {
    .value-ul {
      .item {
        border-bottom: 1px solid #e5e5e5;
        padding: 0.3rem 0;
        .label {
          height: 0.37rem;
          font-size: 0.26rem;
          color: #999999;
          line-height: 0.37rem;
          margin-bottom: 0.09rem;
        }
        .img-value {
          width: 2rem;
          height: 2rem;
        }
        .file-box {
          margin-bottom: 0.2rem;
          .file-value {
            border-bottom: 1px solid #0094ee;
            color: #0094ee;
          }
        }

        .value {
          font-size: 0.3rem;
          color: #333333;
          line-height: 0.42rem;
          word-break: break-all;
        }
      }
      .item:last-of-type {
        border: none;
      }
    }
  }

  .process {
    margin-bottom: 1.24rem;
    .title {
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
      height: 0.42rem;
      font-size: 0.3rem;
      font-weight: bolder;
      color: #333333;
      line-height: 0.42rem;
    }
    .process-ul {
      .item {
        display: flex;
        .left {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 0.9rem;
          margin-right: 0.2rem;
          .avatar {
            width: 0.9rem;
            height: 0.9rem;
            border-radius: 0.45rem;
          }
          .split {
            width: 0.02rem;
            background: #cccccc;
            flex: 1;
          }
        }

        .right {
          flex: 1;
          margin-bottom: 0.6rem;
          .row1 {
            display: flex;
            justify-content: space-between;
            .name {
              font-size: 0.3rem;
              color: #333333;
              line-height: 0.42rem;
            }
            .time {
              font-size: 0.26rem;
              color: #999999;
              line-height: 0.37rem;
              white-space: nowrap;
            }
          }
          .user-name {
            height: 0.37rem;
            font-size: 0.26rem;
            color: #999999;
            line-height: 0.37rem;
          }
          .status {
            height: 0.37rem;
            font-size: 0.26rem;
            color: #999999;
            line-height: 0.37rem;
          }
          .comment {
            margin-top: 0.2rem;
            width: 5.85rem;
            background: #f1f5f6;
            border-radius: 0 0.16rem 0.16rem 0.16rem;
            padding: 0.16rem;
            font-size: 0.22rem;
            color: #666666;
            line-height: 0.3rem;
          }
        }
      }
      .item:last-of-type {
        .left {
          .split {
            display: none;
          }
        }
        .right {
          margin-bottom: 0.6rem;
        }
      }
    }
  }
  .footer-buttons {
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 0.3rem;
    width: 7.5rem;
    height: 1.04rem;
    background: #ffffff;
    box-shadow: 0 0.06rem 0.3rem 0 rgba(0, 0, 0, 0.1);
    align-items: center;
    .pass {
      width: 2.16rem;
      height: 0.8rem;
      background: #0094ee;
      border-radius: 0.4rem 0 0 0.4rem;
      font-size: 0.26rem;
      color: #ffffff;
      line-height: 0.37rem;
    }
    .reject-btn {
      width: 2.16rem;
      height: 0.8rem;
      background: #e5f4fd;
      border-radius: 0 0.4rem 0.4rem 0;
      font-size: 0.26rem;
      color: #ffffff;
      line-height: 0.37rem;
      font-size: 0.26rem;
      color: #0094ee;
      line-height: 0.37rem;
    }

    .icon-text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 2.5rem;
      .img {
        width: 0.4rem;
        height: 0.4rem;
      }
      .text {
        width: 0.52rem;
        height: 0.37rem;
        font-size: 0.26rem;
        color: #333333;
        line-height: 0.37rem;
        white-space: nowrap;
      }
    }
  }

  .approval-box {
    width: 5.7rem;
    background: #ffffff;
    border-radius: 0.16rem;
    .title {
      height: 0.9rem;
      font-size: 0.32rem;
      font-weight: bold;
      color: #333333;
      line-height: 0.45rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .app {
      .message {
        width: 5.3rem;
        height: 2.09rem;
        background: #f4f9fb;
        margin-left: 0.2rem;
        resize: none;
        padding: 0.2rem;
      }

      .footer {
        height: 0.9rem;
        display: flex;
        align-items: center;
        font-size: 0.32rem;
        color: #0094ee;
        line-height: 0.45rem;

        .cancel {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .confirm {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>
