<!--  -->
<template>
  <div class="box">
    <van-nav-bar
      v-if="topObj.isShow || !isWeixin"
      :style="
        'background-color:' +
        topObj.bgColor +
        ';height:' +
        topObj.height +
        'rem;line-height:' +
        topObj.height +
        'rem;'
      "
      :title="topObj.title"
      class="top-bar"
    >
      <template #left>
        <div class="left-box" @click="backFun">
          <van-icon
            v-if="topObj.isBack"
            :color="topObj.txtColor"
            name="arrow-left"
            :size="18"
          />
        </div>
      </template>
      <template #title>
        <p class="title" :style="'color:' + topObj.txtColor">
          {{ topObj.title }}
        </p>
      </template>
    </van-nav-bar>
    <div class="line-bar"></div>
    <div
      class="main"
      :style="`height:${isWeixin ? '100vh' : 'calc(100vh - 1rem)'}`"
    >
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { countUnReadMsg } from "@/api/message/index.js";
import { isWeixin } from "@/utils/isWeixin.js";

export default {
  data() {
    return {
      msgCount: 0,
      isWeixin,
      topObj: {
        bgColor: "",
        height: 1,
        title: "",
        txtColor: "",
        isBack: true,
        isMsg: false,
        isShow: false,
      },
    };
  },

  props: {},

  components: {},

  computed: {},
  watch: {
    $route(val) {
      const { title, txtColor, bgColor, isShow } = val.meta;
      this.topObj.title = title;
      this.topObj.txtColor = txtColor || "#333";
      this.topObj.bgColor = bgColor || "#fff";
      this.topObj.isShow = isShow;
      console.log(this.topObj);
    },
  },
  mounted() {
    const { title, txtColor, bgColor, isShow } = this.$route.meta;
    this.topObj.title = title;
    this.topObj.txtColor = txtColor || "#333";
    this.topObj.bgColor = bgColor || "#fff";
    this.topObj.isShow = isShow;
  },

  methods: {
    backFun() {
      this.$router.go(-1);
    },
    getMsgCount() {
      countUnReadMsg().then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.msgCount = data;
          }
        }
      });
    },
    goClockIn() {
      this.$router.push("/clockIn");
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  .top-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem;
    .title {
      width: 33.3%;
      font-weight: 500;
      text-align: center;
      line-height: 0.45rem;
      font-size: 0.32rem;
    }
    .icon {
      width: 33.3%;
      text-align: left;
    }
    .icon-right {
      text-align: right;
    }
  }
  .top-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  .line-bar {
    height: 1rem;
  }
  .main{
    padding-bottom: .3rem;
    overflow: scroll;
  }
}

::v-deep .van-info {
  white-space: nowrap;
}
</style>
