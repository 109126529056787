var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[(_vm.topObj.isShow || !_vm.isWeixin)?_c('van-nav-bar',{staticClass:"top-bar",style:('background-color:' +
      _vm.topObj.bgColor +
      ';height:' +
      _vm.topObj.height +
      'rem;line-height:' +
      _vm.topObj.height +
      'rem;'),attrs:{"title":_vm.topObj.title},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('div',{staticClass:"left-box",on:{"click":_vm.backFun}},[(_vm.topObj.isBack)?_c('van-icon',{attrs:{"color":_vm.topObj.txtColor,"name":"arrow-left","size":18}}):_vm._e()],1)]},proxy:true},{key:"title",fn:function(){return [_c('p',{staticClass:"title",style:('color:' + _vm.topObj.txtColor)},[_vm._v(" "+_vm._s(_vm.topObj.title)+" ")])]},proxy:true}],null,false,3451633924)}):_vm._e(),_c('div',{staticClass:"line-bar"}),_c('div',{staticClass:"main",style:(`height:${_vm.isWeixin ? '100vh' : 'calc(100vh - 1rem)'}`)},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }