import request from "@/utils/request";

//获取固定资产详情
export function editFixedAssets(data) {
  return request({
    url: "/fixed-assets/editFixedAssets",
    method: "post",
    data: data,
  });
}

//获取固定资产详情
export function queryFixedAssetsInfo(params) {
  return request({
    url: "/fixed-assets/queryFixedAssetsInfo",
    method: "get",
    params: params,
  });
}

//获取固定资产列表
export function queryFixedAssetsList(params) {
  return request({
    url: "/fixed-assets/queryFixedAssetsList",
    method: "get",
    params: params,
  });
}
//获取固定资产列表
export function queryMyFixedAssetsList(params) {
  return request({
    url: "/fixed-assets/queryMyFixedAssetsList",
    method: "get",
    params: params,
  });
}
//新增固定资产
export function saveFixedAssets(data) {
  return request({
    url: "/fixed-assets/saveFixedAssets",
    method: "post",
    data: data,
  });
}
/*
 * 资产故障
   固定资产编码	bizCode
   troubleReason	故障原因
 */
export function assetsTroubled(params) {
  return request({
    url: "/fixed-assets/assetsTroubled",
    method: "GET",
    params,
  });
}
/*
   * 报废资产
     固定资产编码	bizCode
     wasteReason	报废原因
   */
export function wasteAssets(params) {
  return request({
    url: "/fixed-assets/wasteAssets",
    method: "GET",
    params,
  });
}
/*
   * 修改资产状态修改资产状态
     固定资产编码	bizCode
     type	资产状态2-故障3-报废	
   */
export function updateFaStatus(params) {
  return request({
    url: "/fixed-assets/updateFaStatus",
    method: "GET",
    params,
  });
}
